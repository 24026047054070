import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import userReducer from "./userStore";

const persistConfig = {
  key: process.env.REACT_APP_STORAGE_KEY,
  storage
};

const rootReducer = combineReducers({
  user: userReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_ENV !== "production",
  middleware: [thunk]
});

export const persistor = persistStore(store);
