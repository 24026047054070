import React, { useEffect } from "react";
import { AppProvider, AuthProvider, cookie } from "flair-ui";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "@sentry/react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";

import { logout, updateToken } from "Store/userStore";
import Routes from "Routes";

const App = () => {
  dayjs.extend(customParseFormat);
  dayjs.extend(relativeTime);

  /**
   * dispatch
   */
  const dispatch = useDispatch();

  /**
   * state
   */
  const { token } = useSelector((state) => state.user);

  /**
   * variables
   */
  const globalToken = cookie.get("token");

  /**
   * effect
   */
  useEffect(() => {
    if (!globalToken) {
      dispatch(logout());
    }
  }, [globalToken, dispatch]);

  return (
    <ErrorBoundary fallback={<p>Issue Reported. Team deployed to fix</p>}>
      <AppProvider>
        <AuthProvider
          {...{ token, setToken: (token) => dispatch(updateToken(token)) }}
        >
          <Routes />

          <ToastContainer
            pauseOnFocusLoss={false}
            closeOnClick={false}
            position="top-right"
            closeButton={false}
            autoClose={5000}
            hideProgressBar
            pauseOnHover
            newestOnTop
            rtl={false}
            draggable
          />
        </AuthProvider>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default App;
