import React from "react";
import { store, persistor } from "Store";
import { BrowserTracing } from "@sentry/tracing";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import "react-toastify/dist/ReactToastify.min.css";
import "flair-ui/dist/index.css";
import "Scss/app.scss";

import reportWebVitals from "./reportWebVitals";
import App from "./App";

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: "https://45663ed28d564468a26d100618edbcce@o1301096.ingest.sentry.io/6536655",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
