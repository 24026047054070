import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Fallback } from "flair-ui";

const Public = lazy(() => import("Views/Public"));
const Admin = lazy(() => import("Views/Admin"));
const App = lazy(() => import("Views/App"));

const Template = () => {
  /**
   * state
   */
  const [userType, setUserType] = useState();

  /**
   * effect
   */
  useEffect(() => {
    const env = process.env.REACT_APP_ENV;
    let host = window.location.host.replace(
      process.env.REACT_APP_PARENT_URL,
      ""
    );

    host = (() => {
      if (host.lastIndexOf(".") === host.length - 1) {
        return host.substring(0, host.length - 1);
      }

      return host;
    })();

    if (env === "local") {
      return setUserType("app");
    }

    if (["production", "staging"].includes(env)) {
      if (host.includes("secure")) {
        return setUserType("admin");
      }
      if (host.includes("app")) {
        return setUserType("app");
      }

      return setUserType("public");
    }
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        {userType === "public" && <Public />}
        {userType === "app" && <App />}
        {userType === "admin" && <Admin />}
      </Suspense>
    </BrowserRouter>
  );
};

/**
 * named routes
 */
export const namedRoutes = {
  index: "/",
  public: {
    jobs: {
      index: "/jobs",
      details: "/jobs/:id"
    },
    faqs: {
      index: "/faqs"
    },
    mentorship: {
      index: "/mentorship"
    },
    training: {
      index: "/training"
    },
    termsAndCondition: {
      index: "/terms-and-conditions"
    },
    privacyPolicy: {
      index: "/privacy-policy"
    },
    updateEmail: "/update-email"
  },
  auth: {
    login: "/auth/login",
    verify: "/auth/verify",
    authorize: "/auth/authorize",
    forgotten: "/auth/forgotten",
    reset: "/auth/password-reset",
    signup: {
      index: "/auth/signup",
      page: "/auth/signup/:page",
      employer: {
        index: "/auth/signup/employer",
        invite: "/auth/signup/employer/invite",
        companyDetails: {
          index: "/auth/signup/employer/company-details/:tab?",
          subTab: "/auth/signup/employer/company-details/:tab/:subTab?"
        },
        preview: "/auth/signup/employer/preview",
        completeOnboarding: "/auth/signup/employer/complete-onboarding"
      },
      mentor: {
        index: "/auth/signup/mentor"
      },
      candidate: {
        index: "/auth/signup/candidate",
        career: "/auth/signup/candidate/career",
        identified: "/auth/signup/candidate/identified",
        profileSetup: "/auth/signup/candidate/profile-setup"
      },
      trainer: {
        index: "/auth/signup/trainer"
      }
    }
  },
  dashboard: {
    candidate: {
      index: "/dashboard",
      profile: "/dashboard/profile",
      jobPreference: {
        index: "/dashboard/job-preference",
        details: "/dashboard/job-preference/details/:id"
      },
      settings: {
        index: "/dashboard/settings",
        accountInfo: {
          index: "/dashboard/settings/account-info",
          delete: "/dashboard/settings/account-info/delete"
        },
        password: "/dashboard/settings/password",
        notification: "/dashboard/settings/notification",
        terms: "/dashboard/settings/terms-of-service",
        policy: "/dashboard/settings/privacy-policy"
      }
    },
    company: {
      index: "/dashboard",
      jobs: {
        index: "/dashboard/jobs",
        jobPosts: {
          index: "/dashboard/jobs/posts/:tab?",
          details: "/dashboard/jobs/posts/:id/details",
          edit: "/dashboard/jobs/posts/:id/edit",
          create: "/dashboard/jobs/posts/create"
        },
        applications: {
          index: "/dashboard/jobs/applications/:tab?",
          details: "/dashboard/jobs/applications/:id/details/:tab?"
        }
      },
      profile: { index: "/dashboard/profile" },
      candidate: { index: "/dashboard/candidate" },
      talentPool: {
        index: "/dashboard/talent-pool",
        saveForLater: {
          index: "/dashboard/talent-pool/save-for-later",
          details: "/dashboard/talent-pool/save-for-later/:id"
        },
        history: {
          index: "/dashboard/talent-pool/history",
          details: {
            index: "/dashboard/talent-pool/history/details/:id",
            content: "/dashboard/talent-pool/history/details/:id/content",
            recipients: "/dashboard/talent-pool/history/details/:id/recipients"
          }
        },
        templates: {
          index: "/dashboard/talent-pool/templates",
          create: "/dashboard/talent-pool/templates/create",
          details: "/dashboard/talent-pool/templates/details/:id"
        },
        details: { index: "/dashboard/talent-pool/details/:id" }
      },
      nssRequest: {
        index: "/dashboard/nss-request",
        connect: {
          index: "/dashboard/nss-request",
          create: "/dashboard/nss-request/create",
          specify: "/dashboard/nss-request/connect"
        },
        requests: {
          index: "/dashboard/nss-request/requests",
          details: "/dashboard/nss-request/requests/:id/details"
        },
        servicePersonnel: {
          index: "/dashboard/nss-request/service-personnel"
        },
        cart: { index: "/dashboard/nss-request/cart" },
        postings: { index: "/dashboard/nss-request/postings/:tab?" },
        makeRequest: {
          index: "/dashboard/nss-request/make-request/:type?"
        }
      },
      settings: {
        index: "/dashboard/settings",
        profile: {
          index: "/dashboard/settings/company-profile",
          locationUpload: "/dashboard/settings/company-profile/location-upload"
        },
        reps: {
          index: "/dashboard/settings/reps"
        }
      },
      userSettings: {
        index: "/dashboard/user-settings",
        profile: {
          index: "/dashboard/user-settings/profile",
          delete: "/dashboard/user-settings/profile/delete"
        },
        notification: { index: "/dashboard/user-settings/notifications" },
        terms: { index: "/dashboard/user-settings/terms-of-service" },
        policy: { index: "/dashboard/user-settings/privacy-policy" },
        password: { index: "/dashboard/user-settings/password" }
      }
    }
  },
  admin: {
    index: {
      index: "/dashboard",
      companies: {
        index: "/dashboard/companies",
        companies: {
          index: "/dashboard/companies/companies/:tab?",
          details: "/dashboard/companies/companies/:id/details/:tab?"
        },
        approvals: {
          index: "/dashboard/companies/approvals/:tab?",
          details: "/dashboard/companies/approvals/:id/details"
        }
      },
      usersAndRoles: {
        index: "/dashboard/users-and-roles",
        users: {
          index: "/dashboard/users-and-roles/users/:tab?"
        },
        roles: {
          index: "/dashboard/users-and-roles/roles/:tab?"
        }
      },
      jobs: {
        index: "/dashboard/jobs",
        applications: {
          index: "/dashboard/jobs/applications"
        },
        jobPosts: {
          index: "/dashboard/jobs/posts/:tab?",
          details: {
            index: "/dashboard/jobs/posts/details/:id",
            rep: "/dashboard/jobs/posts/details/:id/rep",
            applications: "/dashboard/jobs/posts/details/:id/applications"
          }
        }
      }
    },
    nss: {
      index: "/dashboard",
      companies: {
        index: "/dashboard/companies",
        companies: {
          index: "/dashboard/companies/companies/:tab?",
          details: "/dashboard/companies/companies/:id/details"
        },
        approvals: {
          index: "/dashboard/companies/approvals",
          details: "/dashboard/companies/approvals/:id/details"
        }
      }
    },
    auth: {
      index: "/auth",
      login: "/auth/login",
      forgotten: "/auth/forgotten",
      reset: "/auth/password-reset"
    }
  }
};

export default Template;
