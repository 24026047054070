import { createSlice } from "@reduxjs/toolkit";
import { cookie } from "flair-ui";

const initialState = {
  user: null,
  token: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      const token = payload?.usertoken || payload?.token;

      state.user = payload.usermeta;
      state.token = token;

      cookie.set("token", token);
    },
    logout: (state) => {
      cookie.remove("token");
      state.user = null;
      state.token = null;
    },
    updateUser: (state, { payload }) => {
      state.user = payload;
    },
    updateToken: (state, { payload }) => {
      state.token = payload;
    }
  }
});

export const { login, logout, updateUser, updateToken } = userSlice.actions;

export default userSlice.reducer;
